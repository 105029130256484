<script lang="ts">
	import { Dialog, DialogOverlay } from '@rgossiaux/svelte-headlessui'
	import { page } from '$app/stores'
	import { fade } from 'svelte/transition'
	import { DialogTitle } from '@rgossiaux/svelte-headlessui'
	import { ActionPanel } from '$lib/components'
	import { storage_settings } from '$lib/stores'
	import { accept_all, deny_all, update_consent } from '.'

	export let show_settings: boolean

	$: ({ consent_groups } = $page?.data?.banner || {})
	$: policies = $storage_settings?.policies || [['necessary_storage', true]] // Default to necessary_storage being true
</script>

<Dialog
	open={$storage_settings && $storage_settings?.is_banner_visible}
	class="fixed {show_settings
		? 'inset-0 h-screen overflow-y-auto'
		: 'bottom-0 left-0 right-0 h-[40vh]'} cookie-banner z-30 w-screen"
	aria-labelledby="modal-title"
	role="dialog"
	aria-modal="true"
	static
>
	<div in:fade|global={{ duration: 200 }}>
		<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
		<slot name="overlay" />
		<div class="webkit-fix grid min-h-screen place-items-center p-0 text-center">
			<div
				class="relative w-full transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:rounded sm:align-middle lg:max-w-4xl xl:max-w-5xl"
			>
				<DialogTitle>
					<header class="flex items-center">
						<div class="flex h-12 w-12 flex-shrink-0 items-center justify-items-start text-3xl">
							🍪
						</div>
						<h2 id="modal-title">Cookie-Einstellungen</h2>
						<div class="flex items-center gap-4">
							<div class="hidden sm:flex">
								<a href="/datenschutz" class="text-blue-900 hover:underline">Datenschutzhinweise</a>
							</div>
							<div class="hidden sm:flex">
								<a href="/impressum" class="block text-blue-900 hover:underline">Impressum</a>
							</div>
							<button
								on:click={() => {
									show_settings = false
								}}
								class="p-2 text-blue-900">&larr;</button
							>
						</div>
					</header>
				</DialogTitle>

				<main class="mt-3 sm:mt-5">
					<div>
						{#each consent_groups as { name, label, description, is_not_clickable }, i}
							<ActionPanel
								on:click={() => {
									if (!is_not_clickable) {
										policies.find((policy) => policy[0] === name)[1] = !policies.find(
											(policy) => policy[0] === name,
										)[1]

										$storage_settings.policies = policies
										$storage_settings = $storage_settings
									}
								}}
								{is_not_clickable}
								is_accepted={policies.find((policy) => policy[0] === name)[1]}
							>
								<h3 slot="title">
									{label}
								</h3>
								<svelte:fragment slot="description">{@html description}</svelte:fragment>
							</ActionPanel>
						{/each}
					</div>
				</main>

				<footer
					class="grid flex-shrink-0 grid-flow-dense grid-cols-1 items-center gap-2 md:grid-cols-3 md:gap-3"
				>
					<button
						on:click={() => {
							accept_all(policies)
							show_settings = false
							location.reload() // Reload page to trigger GTM
						}}
						type="button"
						class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-8 py-4 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-3"
					>
						Alle akzeptieren
					</button>
					<button
						on:click={() => {
							update_consent(policies)
							show_settings = false
							location.reload() // Reload page to trigger GTM
						}}
						type="button"
						class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-200 bg-white px-8 py-4 text-base font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:mt-0"
					>
						Auswahl speichern
					</button>
					<button
						on:click={() => {
							deny_all(policies)
							show_settings = false
							location.reload() // Reload page to trigger GTM
						}}
						type="button"
						class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-200 bg-white px-8 py-4 text-base font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0"
					>
						Alle ablehnen
					</button>
				</footer>
				<div class="mb-20 mt-6 flex flex-col place-items-center gap-4 sm:hidden">
					<div class="">
						<a href="/datenschutz" class="text-blue-900 hover:underline">Datenschutzhinweise</a>
					</div>
					<div class="">
						<a href="/impressum" class="block text-blue-900 hover:underline">Impressum</a>
					</div>
				</div>
			</div>
		</div>
	</div></Dialog
>

<style lang="postcss">
	h2 {
		@apply block w-full text-lg font-medium leading-6 text-gray-900;
	}
</style>
