<script>
	import { PUBLIC_GOOGLE_TAG_MANAGER_ID } from '$env/static/public'
	import { get_consent } from '.' // index.ts
	import { browser } from '$app/environment'

	// Load consent settings from localStorage or set default values
	$: settings = get_consent() || {
		ad_storage: 'denied',
		analytics_storage: 'denied',
		functionality_storage: 'denied',
		necessary_storage: 'granted',
		personalization_storage: 'denied',
	}

	$: if (browser) {
		// Initialize dataLayer
		window.dataLayer = window.dataLayer || []

		// Function to update consent settings
		function gtag() {
			dataLayer.push(arguments)
		}

		// Set consent settings
		gtag('consent', 'update', settings)

		// Trigger GTM after consent is configured
		window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
	}
</script>

<svelte:head>
	<script>
		// Define gtag function globally
		function gtag() {
			dataLayer.push(arguments)
		}
	</script>
	<script
		async
		src="https://www.googletagmanager.com/gtm.js?id={PUBLIC_GOOGLE_TAG_MANAGER_ID}&l=dataLayer"
	></script>
</svelte:head>
