<script lang="ts">
	export let is_not_clickable = false
	export let is_accepted: boolean = false
</script>

<div class="action-panel my-4 bg-white shadow sm:rounded-lg">
	<div class="px-4 py-5 sm:p-6">
		<slot name="title"><h3>Titel einfügen</h3></slot>
		<div class="mt-2 sm:flex sm:items-start sm:justify-between">
			<div class="description">
				<slot name="description"
					>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo totam non cumque
					deserunt officiis ex maiores nostrum.</slot
				>
			</div>
			<div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
				<button
					type="button"
					on:click
					class:inactive={is_not_clickable}
					class:picked={is_accepted || is_not_clickable}
					class:not-picked={!is_accepted && !is_not_clickable}
					class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
					role="switch"
					aria-checked={is_accepted || is_not_clickable}
				>
					<span
						aria-hidden="true"
						class:picked={is_accepted || is_not_clickable}
						class:not-picked={!is_accepted && !is_not_clickable}
						class="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
					/>
				</button>
			</div>
		</div>
	</div>
</div>

<style lang="postcss">
	.action-panel :global(h3) {
		@apply text-lg font-medium leading-6 text-gray-900;
	}

	.action-panel :global(.description) {
		@apply max-w-[60ch] text-sm text-gray-500;
	}

	.action-panel .description :global(p) {
		@apply mb-2;
	}

	span.picked {
		@apply translate-x-5;
	}

	span.not-picked {
		@apply translate-x-0;
	}

	button.picked {
		@apply bg-blue-900;
	}

	button.not-picked {
		@apply bg-gray-200;
	}

	button.inactive {
		@apply cursor-default bg-blue-200 focus:ring-0;
	}

	/* button.not-picked.inactive {
		@apply cursor-default bg-gray-100 focus:ring-0;
	} */
</style>
