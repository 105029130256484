<script lang="ts">
	import { page } from '$app/stores'
	import { storage_settings } from '$lib/stores'
	import { fade } from 'svelte/transition'
	import { accept_all } from '.'

	export let show_settings = false

	type Policy = [string, boolean]
	type Policies = Policy[]

	let banner: HTMLElement
	let policies: Policies

	$: ({ text } = $page?.data?.banner || {})
	// Turn policies array into a JS map if cookie_settings is defined (otherwise into an empty map)
	$: policies = $storage_settings?.policies || [['necessary_storage', true]] // Default to necessary_storage being true
	$: banner_height = banner?.offsetHeight
</script>

<div style="height: {banner_height}px">&nbsp;</div>

<div
	bind:this={banner}
	class="cookie-banner webkit-fix fixed bottom-0 left-0 right-0 z-30 w-screen items-end justify-center text-center sm:p-4"
>
	<div
		in:fade|global={{ duration: 200 }}
		class="relative grid max-h-[33vh] min-h-0 w-full transform grid-rows-[1fr_auto] overflow-y-auto rounded-t border-t border-gray-200 bg-white p-4 text-left shadow-xl transition-all sm:rounded"
	>
		<main class="max-h-full min-h-[4rem] overflow-y-auto">
			<div class="banner-text">
				{@html text}
			</div>
		</main>

		<footer
			class="mt-5 grid grid-cols-1 items-center sm:mt-10 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 md:grid-cols-3 lg:grid-cols-4"
		>
			<button
				on:click={() => {
					// acceptAllGroups()
					if ($storage_settings) $storage_settings.is_banner_visible = false
					accept_all(policies)
					// Reload page to trigger GTM
					location.reload()
				}}
				type="button"
				class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-8 py-4 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 md:col-start-3 lg:col-start-4"
			>
				Alle akzeptieren
			</button>
			<button
				on:click={() => {
					show_settings = true
				}}
				type="button"
				class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-200 bg-white px-8 py-4 text-base font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 md:col-start-2 lg:col-start-3"
			>
				Einstellungen
			</button>
		</footer>
	</div>
</div>

<style lang="postcss">
	.banner-text :global(p) {
		@apply text-sm text-gray-600 sm:leading-relaxed sm:text-gray-500;
	}

	.banner-text :global(p) :global(a) {
		@apply text-blue-900 hover:underline;
	}

	:global(main.settings-off) {
		@apply h-20 overflow-y-scroll border-2 border-dashed border-green-500 bg-red-300;
	}
</style>
