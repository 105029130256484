<script lang="ts">
	import { storage_settings } from '$lib/stores'
	import { Main } from '.'
	import { Settings } from '.'

	let show_settings = false
</script>

{#if $storage_settings && $storage_settings?.is_banner_visible}
	<!-- Add  "on:close={() => (is_visible = false)}" to close modal on background click -->
	{#if !show_settings}
		<Main bind:show_settings />
	{:else}
		<Settings bind:show_settings />
	{/if}
{/if}
