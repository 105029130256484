import { browser } from '$app/environment'
import GTM from './gtm.svelte'

type Policies = [string, boolean][]
type GTM_Consent_Settings = Record<string, 'granted' | 'denied'>

export function get_consent(): GTM_Consent_Settings | undefined {
	// If environment is not browser, return undefined
	if (!browser) return

	// Get consent settings from local storage
	const cookie_settings = JSON.parse(localStorage.cookieSettings || 'null')

	// Return undefined if cookie_settings.policies is not defined
	if (!cookie_settings?.policies) return

	// Function to convert array of policies to gtm consent object
	function array_to_object(policies: Policies) {
		const obj = {}

		policies.forEach((item) => {
			// item[0] is the key, item[1] is the boolean value
			obj[item[0]] = item[1] ? 'granted' : 'denied'
		})

		return obj
	}

	// Convert array of policies to gtm consent object
	const gtm_consent_settings = array_to_object(cookie_settings.policies)

	return gtm_consent_settings
}

export { GTM }
