import { browser } from '$app/environment'
import Main from './main.svelte'
import Settings from './settings.svelte'
import CookieBanner from './cookie-banner.svelte'
import { storage_settings } from '$lib/stores'
// import CookieBanner from './cookie-banner-alt.svelte'
// import CookieBanner from './cookie-banner.svelte'

type Policies = [string, boolean][]

// Function to update some consent policies
function update_consent(policies: Policies): void {
	// Update each storage setting
	update_storage_settings(policies)

	// Update GTM settings
	update_gtm_settings(policies)
}

// Function to accept all consent policies
function accept_all(policies: Policies): void {
	// Loop over policies array and set all to true
	policies.forEach((policy) => {
		policy[1] = true
	})

	// Update local storage settings
	update_storage_settings(policies)

	// Update GTM settings
	update_gtm_settings(policies)
}

function deny_all(policies: Policies): void {
	// Loop over policies array and set all to false
	policies.forEach((policy) => {
		policy[1] = false
	})

	// Set necessary_storage to true
	policies.find((policy) => policy[0] === 'necessary_storage')?.splice(1, 1, true)

	// Update local storage settings
	update_storage_settings(policies)

	// Update GTM settings
	update_gtm_settings(policies)
}

/*
HELPER FUNCTIONS
*/

// Function to update cookie consent settings
function update_storage_settings(policies: Policies): void {
	// Update local storage settings
	storage_settings.update(() => {
		return {
			is_banner_visible: false, // Hide cookie banner
			policies, // Update policies array
		}
	})
}

// Function to update GTM consent settings
function update_gtm_settings(policies: Policies) {
	if (browser) {
		// Create gtm_settings object
		const gtm_consent_settings = {} // gtm_consent_settings[key] = granted/deined

		// Loop over policies array and update gtm_consent_settings
		policies.forEach((policy) => {
			const [key, value] = policy
			gtm_consent_settings[key] = value ? 'granted' : 'denied'
		})

		// Update GTM consent settings
		gtag('consent', 'update', gtm_consent_settings)
	}
}

export { accept_all, CookieBanner, deny_all, Main, Settings, update_consent }
